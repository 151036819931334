.cursor-pointer {
    cursor: pointer;
   
    padding: 8px;
  }
  
  /* Styles for the SVG icon */
  svg {
    display: block; /* This ensures the SVG is properly centered within the container */
    width: 24px;
    height: 24px;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .report-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  /* Styles for the "Report Data" header */
  .report-header {
    margin: 0; /* Remove default margin for proper alignment */
  }
  .custom-label {
    margin: 10px; /* Adjust the margin value as per your preference */
  }